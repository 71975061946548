import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is a Facebook App?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A Facebook App is an interactive application developed to make the use of the core Facebook technology easier and create an extensive social media framework for the app. The Facebook app also integrates Facebook’s News Feed, Notifications, social channel many other features to create awareness and interest in the app."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Do I Need A Facebook App?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Facebook apps can do virtually everything for you. From replying to your customers messages to selling products to them, you can automate Facebook apps. For instance, the Facebook chatbot is one of the best tools for businesses. If you really want to increase your bottom line, you need a Facebook app, created Codestaff professionals."
        }
    }
}

const FacebookDeveloper = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Facebook Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Facebook Developers. Top
                                    companies and start-ups choose Codestaff professional Facebook
                                    Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Facebook Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE FACEBOOK EXPERTS' banner='/facebook-developer.png' bannerAlt='facebook developer banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default FacebookDeveloper