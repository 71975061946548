import React from 'react'
import Layout from '../components/layout'
import FacebookDeveloper from '../components/categories/facebook-developer'
import Seo from '../components/seo'

const FacebookDeveloperPage = ({ location }) => {
  return (
    <Layout location={location}>
      <FacebookDeveloper />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Facebook Developer Freelancers | Codestaff'
  const description =
    'Hire the best Facebook Developer freelancers at Codestaff. Get the top 1% of Facebook Developer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default FacebookDeveloperPage
